<script>
import svgClose from "@/svg/close";
import { mapGetters } from "vuex";

export default {
  name: "app-account",

  components: {
    svgClose,
    accountMenu: () =>
      import(
        /* webpackChunkName: "account-menu" */ "@/components/account-menu"
      ),
  },

  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters("session", ["loggedIn", "accUserInfo"]),
  },

  methods: {
    exit() {
      this.$emit("input", false);
    },
    escapeEvent(event) {
      if (event.which == 27) this.exit();
    },
  },

  watch: {
    $route() {
      this.exit();
    },
    value(value) {
      if (value) {
        document.addEventListener("keyup", this.escapeEvent);
      } else {
        document.removeEventListener("keyup", this.escapeEvent);
      }
    },
  },
};
</script>

<template>
  <div id="app-account" :class="{ active: value }" @click="exit">
    <div class="overlay"></div>
    <div class="wrapper">
      <div class="bar">
        <button type="button" class="button close" @click="exit()">
          <svgClose />
        </button>
      </div>
      <router-link to="/account" class="account_card">
        <div class="da_name" v-if="accUserInfo">
          Hola, {{ accUserInfo.user_name_full }}
        </div>
        <div class="da_name" v-else>Hola,</div>
        <div class="da_email" v-if="accUserInfo">
          {{ accUserInfo.user_email }}
        </div>
      </router-link>
      <hr />
      <div>
        <span>Balance: </span>
        <b
          >{{ accUserInfo.user_credits | formatMoney(0)
          }}{{
            accUserInfo.user_credits > 1 || !accUserInfo.user_credits
              ? " créditos"
              : " crédito"
          }}</b
        >
      </div>
      <hr />
      <accountMenu />
    </div>
  </div>
</template>

<style lang="scss">
#app-account {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.75);
  }
  .wrapper {
    position: absolute;
    width: 17.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    transform: translate3d(100%, 0, 0);
    transition: 0.1s all ease-out;
    padding: 1.75rem;
    @media (min-width: 40em) {
      width: 18.75rem;
    }
  }
  &.active {
    left: 0;
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }

  .bar {
    display: flex;
    justify-content: flex-end;
  }

  .close {
    display: block;
    width: 1rem;
    height: 1rem;
    // padding: 0.875rem;
    padding: 0;
    margin-bottom: 0.75rem;
    border: none;
  }

  .account_card {
    color: $sitio8;
  }

  .da_name {
    font-weight: 900;
  }
  .da_email {
    font-size: 0.875rem;
    opacity: 0.7;
  }

  .menu {
    .menu-list {
      font-weight: bold;
    }
    .icon {
      margin-right: 0.75rem;
    }
  }
}
</style>
